import { Pipe, PipeTransform } from '@angular/core';
import { StrategicProgressEnum } from '@shared/enums/strategic-progress.enum';
import { translate } from '@jsverse/transloco';
import { SharedUtils } from '@shared/utils/shared.utils';

@Pipe({
  name: 'strategicProgressValue',
  standalone: true
})
export class StrategicProgressValuePipe implements PipeTransform {

  transform(value: number, code: StrategicProgressEnum): string {
    if(!SharedUtils.hasValue(value)) return '-';
    let output: string;
    switch(code) {
      case StrategicProgressEnum.CURRENCY:
        output = `${value} €`;
        break;
      case StrategicProgressEnum.PERCENTAGE:
        output = `${value} %`;
        break;
      case StrategicProgressEnum.BOOLEAN:
        output = translate(`LABEL.${!!value ? 'YES' : 'NO'}`);
        break;
      default:
        output = `${value}`;
        break;
    }
    return output;
  }

}
