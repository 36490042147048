import * as i0 from '@angular/core';
import { Injectable, EventEmitter, inject, Component, ChangeDetectionStrategy, Input, Output, ChangeDetectorRef, NgModule } from '@angular/core';
import { Subject, Subscription, filter, startWith, tap } from 'rxjs';
import * as i1$1 from '@angular/router';
import { Router, NavigationEnd, RouterModule } from '@angular/router';
import { SharedModule } from '@nesea/ngx-ui-kit/shared';
import * as i1 from '@angular/common';
import * as i3 from '@jsverse/transloco';
const _c0 = ["*"];
const _c1 = a0 => ({
  "nav-fill": a0
});
const _c2 = (a0, a1) => ({
  "active": a0,
  "disabled": a1
});
function TabComponent_li_0_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMapInterpolate1("bi bi-", ctx_r1.icon, " ms-2");
  }
}
function TabComponent_li_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 1)(1, "button", 2);
    i0.ɵɵlistener("click", function TabComponent_li_0_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.setActive());
    });
    i0.ɵɵelementStart(2, "span");
    i0.ɵɵtext(3);
    i0.ɵɵtemplate(4, TabComponent_li_0_Conditional_4_Template, 1, 3, "i", 3);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const transloco_r3 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(10, _c2, ctx_r1.isActive, ctx_r1.disabled))("routerLink", ctx_r1.routerLink);
    i0.ɵɵattribute("id", ctx_r1.nsaId + "-link")("aria-selected", ctx_r1.isActive)("aria-disabled", ctx_r1.disabled)("title", transloco_r3(ctx_r1.title, ctx_r1.translateParams))("aria-controls", ctx_r1.nsaPanelId)("tabindex", ctx_r1.disabled ? "-1" : "0");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", transloco_r3(ctx_r1.title, ctx_r1.translateParams), " ");
    i0.ɵɵadvance();
    i0.ɵɵconditional(!!ctx_r1.icon ? 4 : -1);
  }
}
class TabsService {
  constructor() {
    this._activeTab$ = new Subject();
  }
  get activeTab$() {
    return this._activeTab$.asObservable();
  }
  setActiveTab(id) {
    this._activeTab$.next(id);
  }
  static {
    this.ɵfac = function TabsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TabsService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TabsService,
      factory: TabsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class TabsetComponent {
  constructor() {
    this.select = new EventEmitter();
    this.add$ = new Subject();
    this.remove$ = new Subject();
    this._router = inject(Router);
    this._subs = new Subscription();
    this._tabList = [];
    this._currentUrl = '';
  }
  ngOnInit() {
    this._subs.add(this._router.events.pipe(filter(event => event instanceof NavigationEnd), startWith(this._router), tap(event => this._currentUrl = event.url)).subscribe());
    this._subs.add(this.add$.asObservable().pipe(tap(tab => this._add(tab))).subscribe());
    this._subs.add(this.remove$.asObservable().pipe(tap(tab => this._remove(tab))).subscribe());
  }
  ngOnDestroy() {
    this._subs.unsubscribe();
  }
  checkTabs(id) {
    this._tabList.forEach(item => {
      if (item.nsaId !== id) {
        item.isActive = false;
        item.detectChanges();
      }
    });
  }
  _add(tab) {
    if (!this._tabList.some(item => item.nsaId === tab.nsaId) && !tab.routerLink) {
      tab.isActive = !this._tabList.some(({
        isActive
      }) => isActive) && !tab.disabled;
      this._tabList.push(tab);
      if (tab.isActive) this.select.emit({
        id: tab.nsaId,
        routerLink: tab.routerLink,
        extra: tab.extra
      });
    }
    if (!!tab.routerLink && this._currentUrl.includes(this._getRouterLink(tab.routerLink))) {
      tab.isActive = !this._tabList.some(({
        isActive
      }) => isActive) && !tab.disabled;
      this._tabList.push(tab);
      if (tab.isActive) this.select.emit({
        id: tab.nsaId,
        routerLink: tab.routerLink,
        extra: tab.extra
      });
    }
  }
  _remove(tab) {
    this._tabList = this._tabList.filter(item => item.nsaId !== tab.nsaId);
  }
  _getRouterLink(routerLink) {
    if (Array.isArray(routerLink)) return routerLink.join('/');else return routerLink;
  }
  static {
    this.ɵfac = function TabsetComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TabsetComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TabsetComponent,
      selectors: [["nsa-tabset"]],
      inputs: {
        fullWidth: "fullWidth"
      },
      outputs: {
        select: "select"
      },
      ngContentSelectors: _c0,
      decls: 2,
      vars: 3,
      consts: [["role", "tablist", 1, "nav", "nav-tabs", "nsa-nav-tabs", 3, "ngClass"]],
      template: function TabsetComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "ul", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c1, ctx.fullWidth));
        }
      },
      dependencies: [RouterModule, SharedModule, i1.NgClass],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabsetComponent, [{
    type: Component,
    args: [{
      selector: 'nsa-tabset',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [RouterModule, SharedModule],
      template: "<ul\r\n  class=\"nav nav-tabs nsa-nav-tabs\"\r\n  [ngClass]=\"{ 'nav-fill': fullWidth }\"\r\n  role=\"tablist\">\r\n  <ng-content></ng-content>\r\n</ul>\r\n"
    }]
  }], null, {
    fullWidth: [{
      type: Input
    }],
    select: [{
      type: Output
    }]
  });
})();
class TabComponent {
  constructor() {
    this.translateParams = {};
    this.disabled = false;
    this.isActive = false;
    this._cdr = inject(ChangeDetectorRef);
    this._tabset = inject(TabsetComponent);
    this._tabsService = inject(TabsService);
    this._subs = new Subscription();
  }
  ngOnInit() {
    this._tabset.add$.next(this);
    this._subs.add(this._tabsService.activeTab$.pipe(tap(id => {
      if (this.nsaId === id && !this.isActive) {
        this.setActive();
        this.markForCheck();
      }
    })).subscribe());
  }
  ngOnDestroy() {
    this._tabset.remove$.next(this);
    this._subs?.unsubscribe();
  }
  setActive() {
    if (this.disabled) {
      return;
    }
    this.isActive = true;
    if (this.isActive) this._tabset.checkTabs(this.nsaId);
    this._tabset.select.emit({
      id: this.nsaId,
      routerLink: this.routerLink,
      extra: this.extra
    });
  }
  detectChanges() {
    this._cdr.detectChanges();
  }
  markForCheck() {
    this._cdr.markForCheck();
  }
  static {
    this.ɵfac = function TabComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TabComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TabComponent,
      selectors: [["nsa-tab"]],
      inputs: {
        nsaId: "nsaId",
        title: "title",
        nsaPanelId: "nsaPanelId",
        routerLink: "routerLink",
        translateParams: "translateParams",
        disabled: "disabled",
        icon: "icon",
        extra: "extra"
      },
      decls: 1,
      vars: 0,
      consts: [["class", "nav-item nsa-nav-item", "role", "presentation", 4, "transloco"], ["role", "presentation", 1, "nav-item", "nsa-nav-item"], ["type", "button", "role", "tab", 1, "nav-link", 3, "click", "ngClass", "routerLink"], [3, "class"]],
      template: function TabComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TabComponent_li_0_Template, 5, 13, "li", 0);
        }
      },
      dependencies: [RouterModule, i1$1.RouterLink, SharedModule, i1.NgClass, i3.TranslocoDirective],
      styles: ["[_nghost-%COMP%]{display:contents}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabComponent, [{
    type: Component,
    args: [{
      selector: 'nsa-tab',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [RouterModule, SharedModule],
      template: "<li\r\n  *transloco=\"let transloco\"\r\n  class=\"nav-item nsa-nav-item\"\r\n  role=\"presentation\">\r\n  <button\r\n    [attr.id]=\"nsaId + '-link'\"\r\n    [attr.aria-selected]=\"isActive\"\r\n    [attr.aria-disabled]=\"disabled\"\r\n    [attr.title]=\"transloco(title, translateParams)\"\r\n    [attr.aria-controls]=\"nsaPanelId\"\r\n    [attr.tabindex]=\"disabled ? '-1' : '0'\"\r\n    class=\"nav-link\"\r\n    [ngClass]=\"{ 'active': isActive, 'disabled': disabled }\"\r\n    type=\"button\"\r\n    role=\"tab\"\r\n    [routerLink]=\"routerLink\"\r\n    (click)=\"setActive()\">\r\n    <span>\r\n      {{ transloco(title, translateParams) }}\r\n      @if(!!icon) {\r\n        <i class=\"bi bi-{{icon}} ms-2\"></i>\r\n      }\r\n    </span>\r\n  </button>\r\n</li>\r\n",
      styles: [":host{display:contents}\n"]
    }]
  }], null, {
    nsaId: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    title: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    nsaPanelId: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    routerLink: [{
      type: Input
    }],
    translateParams: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    extra: [{
      type: Input
    }]
  });
})();
class TabsModule {
  static {
    this.ɵfac = function TabsModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TabsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TabsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [TabComponent, TabsetComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabsModule, [{
    type: NgModule,
    args: [{
      imports: [TabComponent, TabsetComponent],
      exports: [TabComponent, TabsetComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TabComponent, TabsModule, TabsService, TabsetComponent };
