import { AfterViewInit, ChangeDetectionStrategy, Component, computed, effect, inject, input, InputSignal, signal, Signal, ViewChild, WritableSignal } from '@angular/core';
import { CalendarOptions, ViewApi } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import itLocale from '@fullcalendar/core/locales/it';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { IUser } from '@core/models/interfaces/user.interface';
import { PermissionEnum } from '@core/enums/permission.enum';
import { IWeekWorktime } from '@shared/models/interfaces/week-worktime.interface';
import { DateUtils } from '@shared/utils/date.utils';
import { catchError, filter, forkJoin, iif, map, of, switchMap, take, tap } from 'rxjs';
import { IHoliday } from '@shared/models/interfaces/holiday.interface';
import { ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { ToastService } from '@nesea/ngx-ui-kit/toast';
import { TimesheetService } from '@shared/services/timesheet.service';
import { SpinnerService } from '@nesea/ngx-ui-kit/spinner';
import { toSignal } from '@angular/core/rxjs-interop';
import { IAbsenceTimesheet, IActivityTimesheet, IOrderTimesheet, IOvertimeTimesheet, ITimesheet, IWorkingDayTimesheet } from '@shared/models/interfaces/timesheet.interface';
import { ITypology, ITypologyIT } from '@shared/models/interfaces/typology.interface';
import { OrderTypeEnum, OrderTypeIdEnum } from '@shared/enums/order-type.enum';
import { translate } from '@jsverse/transloco';
import { Dictionary, EventImpl } from '@fullcalendar/core/internal';
import { Tooltip } from 'bootstrap';
import { TimesheetUtils } from '@shared/utils/timesheet.utils';
import { FundedTimesheetOrderUpdateModalComponent, IFundedTimesheetOrderUpdateModalInput, IFundedTimesheetOrderUpdateModalOutput } from '@shared/modals/funded-timesheet-order-update-modal/funded-timesheet-order-update-modal.component';
import { OrderActivityTypeEnum, OrderActivityTypeIdEnum } from '@shared/enums/order-activity-type.enum';
import { FundedTimesheetService } from '@shared/services/funded-timesheet.service';
import { IFundedTimesheet, IFundedWorkingDayTimesheet } from '@shared/models/interfaces/funded-timesheet.interface';
import { FundedTimesheetStatusEnum, FundedTimesheetStatusIdEnum } from '@shared/enums/funded-timesheet-status.enum';
import { FundedTimesheetOrderDeleteModalComponent, IFundedTimesheetOrderDeleteModalInput, IFundedTimesheetOrderDeleteModalOutput } from '@shared/modals/funded-timesheet-order-delete-modal/funded-timesheet-order-delete-modal.component';
import { ConfirmModalComponent, IConfirmModalOutput } from '@shared/components/confirm-modal/confirm-modal.component';
import { WINDOW } from '@core/tokens';

enum TimesheetOrderEnum {
  WORK,
  FUNDED_WORK,
  OVERTIME,
  ABSENCE,
  ACTIVITY
}

@Component({
  selector: 'nsf-funded-timesheet',
  templateUrl: './funded-timesheet.component.html',
  styleUrl: './funded-timesheet.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FundedTimesheetComponent implements AfterViewInit {

  @ViewChild('fullCalendar') fullCalendar: FullCalendarComponent;

  userData: InputSignal<IUser> = input.required<IUser>();
  sessionUserPermissions: InputSignal<PermissionEnum[]> = input.required<PermissionEnum[]>();
  userWorktime: InputSignal<IWeekWorktime[]> = input<IWeekWorktime[]>();
  orderTypeOptions: InputSignal<ITypology<OrderTypeEnum, OrderTypeIdEnum>[]> = input<ITypology<OrderTypeEnum, OrderTypeIdEnum>[]>();
  orderActivityTypeOptions: InputSignal<ITypologyIT<OrderActivityTypeEnum, OrderActivityTypeIdEnum>[]> = input<ITypologyIT<OrderActivityTypeEnum, OrderActivityTypeIdEnum>[]>();
  month: InputSignal<number> = input.required<number>();
  year: InputSignal<number> = input.required<number>();

  isSpinnerLoading: Signal<boolean> = toSignal(inject(SpinnerService).loading$);

  fullCalendarOptions: WritableSignal<CalendarOptions> = signal<CalendarOptions>({
    plugins: [
      dayGridPlugin,
      interactionPlugin,
      bootstrap5Plugin
    ],
    initialView: 'dayGridMonth',
    locale: itLocale,
    themeSystem: 'bootstrap5',
    weekends: false,
    headerToolbar: false,
    dayHeaderClassNames: 'nsf-timesheet-header-cell',
    dayCellClassNames: 'nsf-timesheet-day-cell',
    eventClassNames: 'nsf-timesheet-event',
    eventOrder: 'type, name',
    eventDidMount: ({ el, event }): void => this._onEventMounted(el, event),
    dateClick: ({ date }): void => this._onDateClick(date),
    eventClick: ({ event }): void => this._onEventClick(event),
    eventAllow: () => false,
    windowResize: ({ view }) => this._onWindowResize(view)
  });

  showHolidays: Signal<boolean> = computed(() => !!this.fullCalendarOptions().weekends);

  timesheet: WritableSignal<ITimesheet> = signal(null);
  fundedTimesheet: WritableSignal<IFundedTimesheet> = signal(null);
  fundedTimesheetStatus: Signal<ITypologyIT<FundedTimesheetStatusEnum ,FundedTimesheetStatusIdEnum>> = computed(() => this.fundedTimesheet()?.stato);

  isTimesheetError: WritableSignal<boolean> = signal(true);
  isTimesheetDraft: Signal<boolean> = computed(() => !this.fundedTimesheet() || this.fundedTimesheetStatus()?.code === FundedTimesheetStatusEnum.DRAFT);
  isTimesheetInProgress: Signal<boolean> = computed(() => !this.fundedTimesheet() || this.fundedTimesheetStatus()?.code === FundedTimesheetStatusEnum.IN_PROGRESS);
  isTimesheetWorked: Signal<boolean> = computed(() => !this.fundedTimesheet() || this.fundedTimesheetStatus()?.code === FundedTimesheetStatusEnum.WORKED);

  currentMonthTitle: WritableSignal<string> = signal(null);
  previousDate: WritableSignal<Date> = signal(DateUtils.firstDayOfCurrentMonth());
  currentDate: WritableSignal<Date> = signal(DateUtils.firstDayOfCurrentMonth());

  currentYear: Signal<number> = computed(() => this.currentDate().getFullYear());
  currentMonth: Signal<number> = computed(() => this.currentDate().getMonth() + 1);

  private _window: Window = inject(WINDOW);
  private _modalViewerService: ModalViewerService = inject(ModalViewerService);
  private _toastService: ToastService = inject(ToastService);
  private _timesheetService: TimesheetService = inject(TimesheetService);
  private _fundedTimesheetService: FundedTimesheetService = inject(FundedTimesheetService);

  private _userHolidays: IHoliday[] = [];

  constructor() {
    effect(() => {
      const date: Date = DateUtils.firstDayOfMonth(new Date(this.year(), this.month(), 1));
      this.currentDate.set(date);
      this.previousDate.set(date);
      this.isTimesheetError.set(false);
    });
  }

  ngAfterViewInit(): void {
    this.fullCalendar.getApi().gotoDate(this.currentDate());
    this._getTimesheet();
  }

  onWorkingweekClick(): void {
    this.fullCalendarOptions.update(currentOptions => ({
      ...currentOptions,
      weekends: !currentOptions.weekends
    }));
  }

  onRemoveOrdersClick(): void {
    const monthHolidays: IHoliday[] = this._userHolidays.filter(({ mese }) => mese === this.currentDate().getMonth() + 1);
    const monthWorkingDays: IWorkingDayTimesheet<IOrderTimesheet>[] = this.timesheet()?.giornoCommessaTimesheet || [];
    // const monthOvertimes: IWorkingDayTimesheet<IOvertimeTimesheet>[] = this.timesheet()?.giornoStraordinario || [];
    const monthAbsences: IAbsenceTimesheet[] = this.timesheet()?.giornoAssenze || [];
    const fundedMonthWorkingDays: IFundedWorkingDayTimesheet[] = this.fundedTimesheet()?.giornoCommessaTimesheet || [];

    this._openOrderDeleteModal(monthHolidays, monthWorkingDays, monthAbsences, fundedMonthWorkingDays);
    // this._openOrderDeleteModal(monthHolidays, monthWorkingDays, monthOvertimes, monthAbsences, fundedMonthWorkingDays);
  }

  onAddOrdersClick(): void {
    const monthHolidays: IHoliday[] = this._userHolidays.filter(({ mese }) => mese === this.currentDate().getMonth() + 1);
    const monthWorkingDays: IWorkingDayTimesheet<IOrderTimesheet>[] = this.timesheet()?.giornoCommessaTimesheet || [];
    // const monthOvertimes: IWorkingDayTimesheet<IOvertimeTimesheet>[] = this.timesheet()?.giornoStraordinario || [];
    const monthAbsences: IAbsenceTimesheet[] = this.timesheet()?.giornoAssenze || [];
    const fundedMonthWorkingDays: IFundedWorkingDayTimesheet[] = this.fundedTimesheet()?.giornoCommessaTimesheet || [];

    this._openOrderUpdateModal(monthHolidays, monthWorkingDays, monthAbsences, fundedMonthWorkingDays);
    // this._openOrderUpdateModal(monthHolidays, monthWorkingDays, monthOvertimes, monthAbsences, fundedMonthWorkingDays);
  }

  onSaveTimesheetClick(): void {
    this._modalViewerService.open(ConfirmModalComponent, {
      size: 'lg',
      title: 'MODAL.CONFIRM.TITLE',
      data: { message: 'TIMESHEET.MODAL.IN_PROGRESS.MESSAGE', translateParams: { currentMonth: this.currentMonthTitle() }, showNote: true }
    }).pipe(
      take(1),
      filter((output: IConfirmModalOutput) => !!output?.outcome),
      map(({ note }) => note),
      switchMap(note => this._fundedTimesheetService.partialWorkTimesheet(this.fundedTimesheet()?.idTimesheet, note).pipe(
        take(1),
        tap(() => {
          this._toastService.showSuccess({
            title: 'TIMESHEET.NOTIFICATION.SUCCESS.IN_PROGRESS.TITLE',
            message: 'TIMESHEET.NOTIFICATION.SUCCESS.IN_PROGRESS.MESSAGE'
          });

          this._getTimesheet();
        })
      ))
    ).subscribe();
  }

  onCloseTimesheetClick(): void {
    this._modalViewerService.open(ConfirmModalComponent, {
      size: 'lg',
      title: 'MODAL.CONFIRM.TITLE',
      data: { message: 'TIMESHEET.MODAL.CLOSE.MESSAGE', translateParams: { currentMonth: this.currentMonthTitle() }, showNote: true }
    }).pipe(
      take(1),
      filter((output: IConfirmModalOutput) => !!output?.outcome),
      map(({ note }) => note),
      switchMap(note => this._fundedTimesheetService.closeTimesheet(this.fundedTimesheet()?.idTimesheet, note).pipe(
        take(1),
        tap(() => {
          this._toastService.showSuccess({
            title: 'TIMESHEET.NOTIFICATION.SUCCESS.CLOSE.TITLE',
            message: 'TIMESHEET.NOTIFICATION.SUCCESS.CLOSE.MESSAGE'
          });

          this._getTimesheet();
        })
      ))
    ).subscribe();
  }

  private _updateCurrentMonth(): void {
    this.currentMonthTitle.set(this.fullCalendar.getApi().getCurrentData().viewTitle);
  }

  private _getTimesheet(): void {
    const currentMonth: number = this.currentMonth();
    const currentYear: number = this.currentYear();

    forkJoin([
      this._timesheetService.getTimesheet(this.userData()?.id, currentMonth, currentYear).pipe(
        catchError(() => of(undefined))
      ),
      this._fundedTimesheetService.getTimesheet(this.userData()?.id, currentMonth, currentYear).pipe(
        catchError(() => of(undefined))
      )
    ]).pipe(
      take(1),
      tap(([timesheet, fundedTimesheet]) => {
        if(!timesheet || !fundedTimesheet) {
          this.isTimesheetError.set(true);
        } else {
          this.isTimesheetError.set(false)
        }
      }),
      switchMap(([timesheet, fundedTimesheet]) => this._timesheetService.getUserHolidays(this.userData()?.id, currentYear).pipe(
        take(1),
        catchError(() => of<IHoliday[]>([])),
        tap(holidays => this._userHolidays = [...holidays]),
        map(() => ({ timesheet, fundedTimesheet }))
      )),
      tap(({ timesheet, fundedTimesheet }) => {
        this._updateCalendar(timesheet, fundedTimesheet);
        this._updateCurrentMonth();
      })
    ).subscribe();
  }

  private _updateCalendar(timesheet: ITimesheet, fundedTimesheet: IFundedTimesheet): void {
    this.fullCalendar.getApi().removeAllEvents();
    this.timesheet.set(timesheet);
    this.fundedTimesheet.set(fundedTimesheet);

    this._addHolidays(this._userHolidays);
    this._addNonWorkingDays(this.userWorktime());
    this._addOrders(timesheet?.giornoCommessaTimesheet);
    this._addOvertimes(timesheet?.giornoStraordinario);
    this._addAbsences(timesheet?.giornoAssenze);
    this._addFundedOrders(fundedTimesheet?.giornoCommessaTimesheet);
    this._addRemainingHours(fundedTimesheet?.giornoCommessaTimesheet);
  }

  private _addHolidays(holidays: IHoliday[]): void {
    (holidays || []).forEach(({ giorno: day, mese: month, nome: name }) => {
      const date: Date = new Date(this.currentDate().getFullYear(), month - 1, day);
      this.fullCalendar.getApi().addEvent({
        id: `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`,
        title: name?.toLocaleUpperCase(),
        date,
        classNames: 'nsf-timesheet-holiday-event',
        allDay: true,
        editable: false,
        display: 'background'
      });
    });
  }

  private _addNonWorkingDays(userWorktime: IWeekWorktime[]): void {
    const firstDayOfMonth: Date = DateUtils.firstDayOfMonth(this.currentDate());
    const lastDayOfMonth: Date = DateUtils.lastDayOfMonth(this.currentDate());
    const monthHolidays: IHoliday[] = this._userHolidays.filter(({ mese }) => mese === this.currentDate().getMonth() + 1);

    DateUtils.daysBetween(firstDayOfMonth, lastDayOfMonth)
      .filter(({ dayOfMonth }) => !monthHolidays.some(({ giorno }) => giorno === dayOfMonth))
      .map(({ dayOfWeek, date }) => {
        const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(userWorktime, date);
        const maxHours: number = workTime?.[dayOfWeek] || 0;

        const output: { date: Date, maxHours: number } = {
          date,
          maxHours
        };

        return output;
      })
      .filter(({ maxHours }) => !maxHours)
      .forEach(({ date }) => {
        this.fullCalendar.getApi().addEvent({
          id: `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`,
          title: translate('LABEL.NON_ACCOUNTABLE'),
          date,
          classNames: 'nsf-timesheet-unavailable-event',
          allDay: true,
          editable: false,
          display: 'background'
        });

        this.fullCalendar.getApi().addEvent({
          id: `${date.getDate()}`,
          title: translate('TIMESHEET.NO_WORKING'),
          date,
          allDay: true,
          editable: false,
          classNames: 'nsf-timesheet-non-working-event'
        });
      });
  }

  private _addOrders(workingDays: IWorkingDayTimesheet<IOrderTimesheet>[]): void {
    (workingDays || []).forEach(({ giorno: day, commesseOreTimesheet: orders }) => {
      if(orders.some(({ code, distacco }) => [OrderTypeEnum.FUNDED_PROJECT, OrderTypeEnum.FUNDED_TRAINING].includes(code) || distacco)) {
        const date: Date = new Date(this.currentDate().getFullYear(), this.currentDate().getMonth(), day);

        if(!this.fullCalendar.getApi().getEventById(`${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`)) {
          this.fullCalendar.getApi().addEvent({
            id: `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`,
            title: translate('LABEL.NON_ACCOUNTABLE'),
            date,
            classNames: 'nsf-timesheet-unavailable-event',
            allDay: true,
            editable: false,
            display: 'background'
          });
        }
      }

      (orders || [])
        .filter(({ code, distacco }) => [OrderTypeEnum.FUNDED_PROJECT, OrderTypeEnum.FUNDED_TRAINING].includes(code) || distacco)
        .forEach(order => {
          const title: string = order.distacco ? translate('TIMESHEET.DETACHMENT') : this.orderTypeOptions().find(({ code }) => order.code === code).name;

          this.fullCalendar.getApi().addEvent({
            id: `${day}_${order.id}`,
            title,
            date: new Date(this.currentDate().getFullYear(), this.currentDate().getMonth(), day),
            allDay: true,
            editable: false,
            classNames: 'nsf-timesheet-order-event',
            extendedProps: { ...order, type: TimesheetOrderEnum.WORK }
          });
      });
    });
  }

  private _addOvertimes(overtimes: IWorkingDayTimesheet<IOvertimeTimesheet>[]): void {
    (overtimes || []).forEach(({ giorno: day, commesseOreTimesheet: orders }) => {
      const date: Date = new Date(this.currentDate().getFullYear(), this.currentDate().getMonth(), day);

      // if(!this.fullCalendar.getApi().getEventById(`${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`)) {
      //   this.fullCalendar.getApi().addEvent({
      //     id: `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`,
      //     title: translate('LABEL.NON_ACCOUNTABLE'),
      //     date,
      //     classNames: 'nsf-timesheet-unavailable-event',
      //     allDay: true,
      //     editable: false,
      //     display: 'background'
      //   });
      // }

      (orders || []).forEach(order => {
        this.fullCalendar.getApi().addEvent({
          id: `${day}_${order.id}`,
          title:`${order.tipo}`,
          date,
          allDay: true,
          editable: false,
          classNames: 'nsf-timesheet-overtime-event',
          extendedProps: { ...order, type: TimesheetOrderEnum.OVERTIME }
        });
      });
    });
  }

  private _addAbsences(absences: IAbsenceTimesheet[]): void {
    const extendedAbsences: (IAbsenceTimesheet & { id: number })[] = [];
    (absences || []).forEach(absence => {
      if(extendedAbsences.find(({ giorno: day }) => day === absence.giorno)) {
        const last = extendedAbsences.reduce((prev, current) => {
          return (!!prev && prev.id > current.id) ? prev : current;
        });
        extendedAbsences.push({
          ...absence,
          id: last.id + 1
        });
      } else {
        extendedAbsences.push({
          ...absence,
          id: 0
        });
      }
    });

    (extendedAbsences || []).forEach(({ id, giorno: day, tipo: type, ore: absenceHours }) => {
      const date: Date = new Date(this.currentDate().getFullYear(), this.currentDate().getMonth(), day);

      if(!this.fullCalendar.getApi().getEventById(`${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`)) {
        this.fullCalendar.getApi().addEvent({
          id: `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`,
          title: translate('LABEL.NON_ACCOUNTABLE'),
          date,
          classNames: 'nsf-timesheet-unavailable-event',
          allDay: true,
          editable: false,
          display: 'background'
        });
      }

      this.fullCalendar.getApi().addEvent({
        id: `${day}_${id}`,
        title: `${type}`,
        date: new Date(this.currentDate().getFullYear(), this.currentDate().getMonth(), day),
        allDay: true,
        editable: false,
        classNames: 'nsf-timesheet-absence-event',
        extendedProps: { name: type, type: TimesheetOrderEnum.ABSENCE }
      });
    });
  }

  private _addFundedOrders(fundedWorkingDays: IFundedWorkingDayTimesheet[]): void {
    (fundedWorkingDays || []).forEach(({ giorno: day, commessa: order, attivita: activity }) => {
      if(!!order || !!activity) {
        const id: string = !!order ? `${day}_${order.id}` : `${day}_${activity.id}`;
        const title: string = !!order ? this.orderTypeOptions().find(({ code }) => order.code === code).name : translate('LABEL.ACTIVITY.TEXT');
        const extendedProps: Dictionary = !!order ? { ...order, type: TimesheetOrderEnum.FUNDED_WORK } : { ...activity, type: TimesheetOrderEnum.ACTIVITY };

        this.fullCalendar.getApi().addEvent({
          id,
          title,
          date: new Date(this.currentDate().getFullYear(), this.currentDate().getMonth(), day),
          allDay: true,
          editable: true,
          classNames: !!order ? 'nsf-timesheet-funded-order-event' : 'nsf-timesheet-funded-activity-event',
          extendedProps
        });
      }
    });
  }

  private _addRemainingHours(fundedWorkingDays: IFundedWorkingDayTimesheet[]): void {
    const firstDayOfMonth: Date = DateUtils.firstDayOfMonth(this.currentDate());
    const lastDayOfMonth: Date = DateUtils.lastDayOfMonth(this.currentDate());
    const monthHolidays: IHoliday[] = this._userHolidays.filter(({ mese }) => mese === this.currentDate().getMonth() + 1);
    const monthWorkingDays: IWorkingDayTimesheet<IOrderTimesheet>[] = this.timesheet()?.giornoCommessaTimesheet || [];
    // const monthOvertimes: IWorkingDayTimesheet<IOvertimeTimesheet>[] = this.timesheet()?.giornoStraordinario || [];
    const monthAbsences: IAbsenceTimesheet[] = this.timesheet()?.giornoAssenze || [];

    /**
     * Escludo:
     * - giorni festivi
     * - giorni in cui è stata consuntivata una commessa finanziata o con distacco
     * - giorno in cui sono state inserite assenze
     */
    DateUtils.daysBetween(firstDayOfMonth, lastDayOfMonth)
      .filter(({ dayOfMonth }) => !monthHolidays.some(({ giorno }) => giorno === dayOfMonth))
      .filter(({ dayOfMonth }) => {
        const orders: IWorkingDayTimesheet<IOrderTimesheet> = monthWorkingDays.find(({ giorno }) => giorno === dayOfMonth);
        return (orders?.commesseOreTimesheet || []).every(({ code, distacco }) => ![OrderTypeEnum.FUNDED_PROJECT, OrderTypeEnum.FUNDED_TRAINING].includes(code) && !distacco);
      })
      // .filter(({ dayOfMonth }) => {
      //   const overtimeDay: IWorkingDayTimesheet<IOvertimeTimesheet> = monthOvertimes.find(({ giorno }) => giorno === dayOfMonth);
      //   return !overtimeDay?.commesseOreTimesheet?.length;
      // })
      .filter(({ dayOfMonth }) => !monthAbsences.some(({ giorno }) => giorno === dayOfMonth))
      .map(({ dayOfMonth, dayOfWeek, date }) => {
        const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.userWorktime(), date);
        const maxHours: number = workTime?.[dayOfWeek] || 0;

        const output: { date: Date, remainingHours: number } = {
          date,
          remainingHours: 0
        };

        if(maxHours > 0) {
          const workingDay: IFundedWorkingDayTimesheet = (fundedWorkingDays || [])
            .find(({ giorno }) => giorno === dayOfMonth);
          let workedHours: number = 0;
          if(!!workingDay?.commessa) {
            workedHours = workingDay.commessa.oreLavorate;
          } else if(!!workingDay?.attivita){
            workedHours = workingDay.attivita.oreLavorate;
          }

          output.remainingHours = (maxHours - workedHours);
        }

        return output;
      })
      .filter(({ remainingHours }) => remainingHours > 0)
      .forEach(({ date, remainingHours }) => {
        this.fullCalendar.getApi().addEvent({
          title: this._window.innerWidth < 768 ? `${TimesheetUtils.parseHours(remainingHours)}`: `${translate('LABEL.RESIDUAL_HOURS.TEXT')}: ${TimesheetUtils.parseHours(remainingHours)}` ,
          date,
          classNames: 'nsf-timesheet-remaining-hours',
          allDay: true,
          editable: false,
          display: 'background'
        });
      });
  }

  private _onEventMounted(el: HTMLElement, event: EventImpl): void {
    if([TimesheetOrderEnum.WORK, TimesheetOrderEnum.FUNDED_WORK, TimesheetOrderEnum.OVERTIME, TimesheetOrderEnum.ACTIVITY].includes(event.extendedProps['type'])) {
      const title: string = [TimesheetOrderEnum.WORK, TimesheetOrderEnum.OVERTIME].includes(event.extendedProps['type']) ?
        `${event.extendedProps['name']} (${event.extendedProps['clientName']})` :
        (event.extendedProps['type'] === TimesheetOrderEnum.ACTIVITY ? `${event.extendedProps['nome']}` : `${event.extendedProps['name']}`);
      let customClass: string = 'nsf-timesheet-tooltip-';
      switch(event.extendedProps['type']) {
        case TimesheetOrderEnum.WORK:
          customClass += 'order';
          break;
        case TimesheetOrderEnum.ABSENCE:
          customClass += 'absence';
          break;
        case TimesheetOrderEnum.FUNDED_WORK:
          customClass += 'funded-order';
          break;
        case TimesheetOrderEnum.ACTIVITY:
          customClass += 'funded-activity';
          break;
        case TimesheetOrderEnum.OVERTIME:
          customClass += 'overtime';
          break;
      }
      customClass += '-event';

      const tooltip = new Tooltip(el, {
        title,
        placement: 'top',
        trigger: 'hover',
        container: 'body',
        customClass
      });
    }
  }

  private _onDateClick(date: Date): void {
    if(!this.isTimesheetError() && !this.isTimesheetWorked() && DateUtils.sameMonth(date, this.currentDate())) {
      const monthHolidays: IHoliday[] = this._userHolidays.filter(({ mese }) => mese === date.getMonth() + 1);
      // const monthOvertimes: IWorkingDayTimesheet<IOvertimeTimesheet>[] = this.timesheet()?.giornoStraordinario || [];
      const monthAbsences: IAbsenceTimesheet[] = this.timesheet()?.giornoAssenze || [];
      const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.userWorktime(), date);
      const maxHours: number = workTime?.[date.getDay()] || 0;

      if(!maxHours) {
        this._toastService.showWarning({
          title: 'TIMESHEET.NOTIFICATION.WARNING.NO_WORKTIME.TITLE',
          message: 'TIMESHEET.NOTIFICATION.WARNING.NO_WORKTIME.MESSAGE',
          translateParams: { hours: maxHours }
        });
      } else if(this._userHolidays.some(({ giorno, mese }) => date.getDate() === giorno && mese === date.getMonth() + 1)) {
        // Festività nazionale
        this._toastService.showWarning({
          title: 'TIMESHEET.NOTIFICATION.WARNING.FESTIVITY.TITLE',
          message: 'TIMESHEET.NOTIFICATION.WARNING.FESTIVITY.MESSAGE'
        });
      } else if(date.getDay() === 0 || date.getDay() === 6) {
        // Sabati e domeniche
        this._toastService.showWarning({
          title: 'TIMESHEET.NOTIFICATION.WARNING.HOLIDAY.TITLE',
          message: 'TIMESHEET.NOTIFICATION.WARNING.HOLIDAY.MESSAGE'
        });
      } else {
        const monthWorkingDays: IWorkingDayTimesheet<IOrderTimesheet>[] = this.timesheet()?.giornoCommessaTimesheet || [];
        const orders: IOrderTimesheet[] = monthWorkingDays.find(({ giorno }) => giorno === date.getDate())?.commesseOreTimesheet || [];
        const nonAccountable: boolean = orders.some(({ code, distacco }) => [OrderTypeEnum.FUNDED_PROJECT, OrderTypeEnum.FUNDED_TRAINING].includes(code) || distacco);
        // const overtimeDay: IWorkingDayTimesheet<IOvertimeTimesheet> = monthOvertimes
        //   .find(({ giorno }) => giorno === date.getDate());
        // const overtimedDay: boolean = !!overtimeDay?.commesseOreTimesheet?.length;
        const absenceDay: boolean = monthAbsences
          .some(({ giorno }) => giorno === date.getDate());

        if(nonAccountable || absenceDay) {
        // if(nonAccountable || overtimedDay || absenceDay) {
          this._toastService.showWarning({
            title: 'TIMESHEET.NOTIFICATION.WARNING.NON_ACCOUNTABLE.TITLE',
            message: 'TIMESHEET.NOTIFICATION.WARNING.NON_ACCOUNTABLE.MESSAGE'
          });
        } else {
          const fundedMonthWorkingDays: IFundedWorkingDayTimesheet[] = this.fundedTimesheet()?.giornoCommessaTimesheet || [];
          const fundedWorkingDay: IFundedWorkingDayTimesheet = fundedMonthWorkingDays.find(({ giorno }) => giorno === date.getDate());
          const unavailable: boolean = !!fundedWorkingDay?.attivita?.oreLavorate || !!fundedWorkingDay?.commessa?.oreLavorate;

          if(unavailable) {
            this._toastService.showWarning({
              title: 'TIMESHEET.NOTIFICATION.WARNING.EXCEEDED.TITLE',
              message: 'TIMESHEET.NOTIFICATION.WARNING.EXCEEDED.MESSAGE',
              translateParams: { hours: fundedWorkingDay?.attivita?.oreLavorate || fundedWorkingDay?.commessa?.oreLavorate }
            });
          } else {
            this._openOrderUpdateModal(monthHolidays, monthWorkingDays, monthAbsences, fundedMonthWorkingDays, date);
            // this._openOrderUpdateModal(monthHolidays, monthWorkingDays, monthOvertimes, monthAbsences, fundedMonthWorkingDays, date);
          }
        }
      }
    }
  }

  private _onEventClick({ start, startEditable, extendedProps }: EventImpl): void {
    if(!this.isTimesheetError() && !this.isTimesheetWorked() && DateUtils.sameMonth(start, this.currentDate())) {
      if(!!startEditable) {
        const monthHolidays: IHoliday[] = this._userHolidays.filter(({ mese }) => mese === start.getMonth() + 1);
        const monthWorkingDays: IWorkingDayTimesheet<IOrderTimesheet>[] = this.timesheet()?.giornoCommessaTimesheet || [];
        // const monthOvertimes: IWorkingDayTimesheet<IOvertimeTimesheet>[] = this.timesheet()?.giornoStraordinario || [];
        const monthAbsences: IAbsenceTimesheet[] = this.timesheet()?.giornoAssenze || [];
        const fundedMonthWorkingDays: IFundedWorkingDayTimesheet[] = this.fundedTimesheet()?.giornoCommessaTimesheet || [];

        const { type } = extendedProps;
        if(type === TimesheetOrderEnum.FUNDED_WORK) {
          this._openOrderUpdateModal(monthHolidays, monthWorkingDays, monthAbsences, fundedMonthWorkingDays, start, { ...extendedProps } as IOrderTimesheet);
          // this._openOrderUpdateModal(monthHolidays, monthWorkingDays, monthOvertimes, monthAbsences, fundedMonthWorkingDays, start, { ...extendedProps } as IOrderTimesheet);
        } else {
          this._openOrderUpdateModal(monthHolidays, monthWorkingDays, monthAbsences, fundedMonthWorkingDays, start, undefined, { ...extendedProps } as IActivityTimesheet);
          // this._openOrderUpdateModal(monthHolidays, monthWorkingDays, monthOvertimes, monthAbsences, fundedMonthWorkingDays, start, undefined, { ...extendedProps } as IActivityTimesheet);
        }
      }
    }
  }

  private _onWindowResize(view: ViewApi): void {
    this._updateCalendar(this.timesheet(), this.fundedTimesheet());
  }

  private _openOrderUpdateModal(holidays: IHoliday[], workingDays: IWorkingDayTimesheet<IOrderTimesheet>[], absences: IAbsenceTimesheet[], fundedWorkingDays: IFundedWorkingDayTimesheet[], startDate?: Date, order?: IOrderTimesheet, activity?: IActivityTimesheet): void {
  // private _openOrderUpdateModal(holidays: IHoliday[], workingDays: IWorkingDayTimesheet<IOrderTimesheet>[], overtimes: IWorkingDayTimesheet<IOvertimeTimesheet>[], absences: IAbsenceTimesheet[], fundedWorkingDays: IFundedWorkingDayTimesheet[], startDate?: Date, order?: IOrderTimesheet, activity?: IActivityTimesheet): void {
    this._modalViewerService.open<IFundedTimesheetOrderUpdateModalInput, IFundedTimesheetOrderUpdateModalOutput>(FundedTimesheetOrderUpdateModalComponent, {
      size: 'lg',
      title: 'TIMESHEET.MODAL.ORDER_UPDATE.TITLE',
      data: {
        userId: this.userData()?.id,
        currentMonth: this.currentDate().getMonth(),
        currentYear: this.currentDate().getFullYear(),
        orderActivityTypeOptions: this.orderActivityTypeOptions(),
        workTime: this.userWorktime(),
        holidays,
        workingDays,
        // overtimes,
        absences,
        fundedWorkingDays,
        startDate,
        order,
        activity
      },
      translateParams: { isEdit: !!order || !!activity, name: !!order ? order.name : (!!activity ? activity.nome : '') }
    }).pipe(
      take(1),
      filter(output => !!output?.outcome),
      switchMap(({ remove, workingDays, excludedDays, isOrder }) => iif(
        () => !!workingDays?.length,
        this._fundedTimesheetService.saveTimesheet(this.userData()?.id, this.currentDate().getMonth() + 1, this.currentDate().getFullYear(), workingDays).pipe(
          take(1),
          tap(() => {
            if(!excludedDays?.length) {
              if(remove) {
                this._toastService.showSuccess({
                  title: 'TIMESHEET.NOTIFICATION.SUCCESS.ORDER_DELETE.TITLE',
                  message: 'TIMESHEET.NOTIFICATION.SUCCESS.ORDER_DELETE.MESSAGE',
                  translateParams: { isOrder }
                });
              } else {
                this._toastService.showSuccess({
                  title: 'TIMESHEET.NOTIFICATION.SUCCESS.ORDER_UPDATE.TITLE',
                  message: 'TIMESHEET.NOTIFICATION.SUCCESS.ORDER_UPDATE.MESSAGE',
                  translateParams: { action: !order && !activity ? 'insert' : 'edit', isOrder }
                });
              }
            } else {
              let daysArray: string[] = excludedDays.map(day => DateUtils.formatDate(new Date(this.currentDate().getFullYear(), this.currentDate().getMonth(), day)));
              this._toastService.showWarning({
                title: 'TIMESHEET.NOTIFICATION.WARNING.ORDER_UPDATE.TITLE',
                message: 'TIMESHEET.NOTIFICATION.WARNING.ORDER_UPDATE.MESSAGE',
                translateParams: { days: daysArray.join(', '), isOrder }
              });
            }
            this._getTimesheet();
          })
        ),
        // TODO: Messaggio di warning (nessun giorno da inserire)
        of(undefined).pipe(
          tap(() => {
            let daysArray: string[] = excludedDays.map(day => DateUtils.formatDate(new Date(this.currentDate().getFullYear(), this.currentDate().getMonth(), day)));
            this._toastService.showWarning({
              title: 'TIMESHEET.NOTIFICATION.WARNING.ORDER_UPDATE.TITLE',
              message: 'TIMESHEET.NOTIFICATION.WARNING.ORDER_UPDATE.MESSAGE',
              translateParams: { days: daysArray.join(', '), isOrder }
            });
          })
        )
      ))
    ).subscribe();
  }

  private _openOrderDeleteModal(holidays: IHoliday[], workingDays: IWorkingDayTimesheet<IOrderTimesheet>[], absences: IAbsenceTimesheet[], fundedWorkingDays: IFundedWorkingDayTimesheet[]): void {
  // private _openOrderDeleteModal(holidays: IHoliday[], workingDays: IWorkingDayTimesheet<IOrderTimesheet>[], overtimes: IWorkingDayTimesheet<IOvertimeTimesheet>[], absences: IAbsenceTimesheet[], fundedWorkingDays: IFundedWorkingDayTimesheet[]): void {
    this._modalViewerService.open<IFundedTimesheetOrderDeleteModalInput, IFundedTimesheetOrderDeleteModalOutput>(FundedTimesheetOrderDeleteModalComponent, {
      size: 'lg',
      title: 'TIMESHEET.MODAL.ORDER_DELETE.TITLE',
      data: {
        userId: this.userData()?.id,
        currentMonth: this.currentDate().getMonth(),
        currentYear: this.currentDate().getFullYear(),
        orderActivityTypeOptions: this.orderActivityTypeOptions(),
        workTime: this.userWorktime(),
        holidays,
        workingDays,
        // overtimes,
        absences,
        fundedWorkingDays
      }
    }).pipe(
      take(1),
      filter(output => !!output?.outcome),
      tap(({ isOrder, multiple }) => this._toastService.showSuccess({
        title: 'TIMESHEET.NOTIFICATION.SUCCESS.ORDER_DELETE.TITLE',
        message: 'TIMESHEET.NOTIFICATION.SUCCESS.ORDER_DELETE.MESSAGE',
        translateParams: { isOrder, multiple }
      })),
      tap(() => this._getTimesheet())
    ).subscribe();
  }

}
