import { NgModule } from '@angular/core';
import { CommonModule, PercentPipe, TitleCasePipe } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ButtonModule } from '@nesea/ngx-ui-kit/button';
import { FormModule } from '@nesea/ngx-ui-kit/form';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ModalModule } from '@nesea/ngx-ui-kit/modal';
import { AccordionModule } from '@nesea/ngx-ui-kit/accordion';
import { AlertModule } from '@nesea/ngx-ui-kit/alert';
import { CardModule } from '@nesea/ngx-ui-kit/card';
import { TableModule } from '@nesea/ngx-ui-kit/table';
import { TabsModule } from '@nesea/ngx-ui-kit/tabs';
import { LetDirective, PushPipe } from '@ngrx/component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TypologySortPipe } from '@shared/pipes/typology-sort.pipe';
import { TimesheetComponent } from '@shared/components/timesheet/timesheet.component';
import { TimesheetSearchModalComponent } from '@shared/modals/timesheet-search-modal/timesheet-search-modal.component';
import { TimesheetSummaryModalComponent } from '@shared/modals/timesheet-summary-modal/timesheet-summary-modal.component';
import { TimesheetOrderUpdateModalComponent } from '@shared/modals/timesheet-order-update-modal/timesheet-order-update-modal.component';
import { TimesheetSendUploadModalComponent } from './modals/timesheet-send-upload-modal/timesheet-send-upload-modal.component';
import { FileSaverModule } from '@nesea/ngx-ui-kit/file-saver';
import { TimesheetSummaryOrdersComponent } from '@shared/modals/timesheet-summary-modal/components/timesheet-summary-orders/timesheet-summary-orders.component';
import { TimesheetSummaryAbsencesComponent } from './modals/timesheet-summary-modal/components/timesheet-summary-absences/timesheet-summary-absences.component';
import { TimesheetSummaryOvertimesComponent } from './modals/timesheet-summary-modal/components/timesheet-summary-overtimes/timesheet-summary-overtimes.component';
import { TimesheetSummaryActivationsComponent } from './modals/timesheet-summary-modal/components/timesheet-summary-activations/timesheet-summary-activations.component';
import { TimesheetSummaryHistoryComponent } from './modals/timesheet-summary-modal/components/timesheet-summary-history/timesheet-summary-history.component';
import { TimesheetSummaryDocumentsComponent } from './modals/timesheet-summary-modal/components/timesheet-summary-documents/timesheet-summary-documents.component';
import { TimesheetOrderDeleteModalComponent } from '@shared/modals/timesheet-order-delete-modal/timesheet-order-delete-modal.component';
import { RequestStatusPipe } from '@shared/pipes/request-status.pipe';
import { FilePreviewModalComponent } from '@shared/modals/file-preview-modal/file-preview-modal.component';
import { SanitizeUrlPipe } from '@shared/pipes/sanitize-url.pipe';
import { TranslocoDatePipe } from '@jsverse/transloco-locale';
import { SicknessTypePipe } from '@shared/pipes/sickness-type.pipe';
import { MetadataStatusPipe } from '@shared/pipes/metadata-status.pipe';
import { FundedTimesheetComponent } from '@shared/components/funded-timesheet/funded-timesheet.component';
import { OrderPipe } from '@shared/pipes/order.pipe';
import { FundedActivityTypePipe } from '@shared/pipes/funded-activity-type.pipe';
import { FundedTimesheetOrderUpdateModalComponent } from '@shared/modals/funded-timesheet-order-update-modal/funded-timesheet-order-update-modal.component';
import { TimesheetStatusPipe } from '@shared/pipes/timesheet-status.pipe';
import { TypologySortITPipe } from '@shared/pipes/typology-it-sort.pipe';
import { FundedTimesheetOrderDeleteModalComponent } from '@shared/modals/funded-timesheet-order-delete-modal/funded-timesheet-order-delete-modal.component';
import { HoursPipe } from '@shared/pipes/hours.pipe';
import { SkillCategoryPipe } from '@shared/pipes/skill-category.pipe';
import { PermissionSortPipe } from '@shared/pipes/permission-sort.pipe';
import { StrategicProgressPipe } from '@shared/pipes/strategic-progress.pipe';
import { StrategicProgressValuePipe } from '@shared/pipes/strategic-progress-value.pipe';
import { StepperModule } from '@nesea/ngx-ui-kit/stepper';

const ngxUiKitModules = [
  AccordionModule,
  AlertModule,
  ButtonModule,
  CardModule,
  FileSaverModule,
  FormModule,
  ModalModule,
  StepperModule,
  TableModule,
  TabsModule
];

const ngrxModules = [
  LetDirective,
  PushPipe
];

const components = [
  TimesheetSummaryOrdersComponent,
  TimesheetSummaryAbsencesComponent,
  TimesheetSummaryOvertimesComponent,
  TimesheetSummaryActivationsComponent,
  TimesheetSummaryHistoryComponent,
  TimesheetSummaryDocumentsComponent,
  TimesheetComponent,
  FundedTimesheetComponent
];

const modals = [
  ConfirmModalComponent,
  TimesheetOrderUpdateModalComponent,
  TimesheetOrderDeleteModalComponent,
  TimesheetSearchModalComponent,
  TimesheetSendUploadModalComponent,
  TimesheetSummaryModalComponent,
  FilePreviewModalComponent,
  FundedTimesheetOrderUpdateModalComponent,
  FundedTimesheetOrderDeleteModalComponent
];

const standalonePipes = [
  FundedActivityTypePipe,
  HoursPipe,
  MetadataStatusPipe,
  OrderPipe,
  PercentPipe,
  PermissionSortPipe,
  RequestStatusPipe,
  SanitizeUrlPipe,
  SicknessTypePipe,
  SkillCategoryPipe,
  StrategicProgressPipe,
  StrategicProgressValuePipe,
  TimesheetStatusPipe,
  TitleCasePipe,
  TranslocoDatePipe,
  TypologySortPipe,
  TypologySortITPipe
];

@NgModule({
  declarations: [
    ...modals,
    ...components
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    FullCalendarModule,
    ...ngxUiKitModules,
    ...ngrxModules,
    ...standalonePipes
  ],
  exports: [
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    ...components,
    ...ngxUiKitModules,
    ...ngrxModules,
    ...standalonePipes
  ],
  providers: [
    ...standalonePipes
  ]
})
// TODO: Check modules
export class SharedModule { }
